.wrapper {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 2147483647;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-section {
    width: 480px;
    height: calc(-108px + 100dvh);
    max-height: 704px;
    border-radius: 16px;
    margin-bottom: 16px;
    margin-inline: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .header {
        flex: 0;
        background-color: var(--color-blue);
        color: #fff;
        position: relative;


        &.bottom-rounded {
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
        }

        .leave-chat-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #fff;
            border-color: #fff;
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 400;
        }

    }

    .content {
        flex: 1;
        height: 100%;
        overflow-y: auto;
    }

    .footer {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        flex: 0;
    }

}

.fab {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-bottom: 16px;
    margin-inline: 16px;
    background-color: var(--color-blue);
    color: #fff;
    font-size: 20px;

    &:active {
        transform: scale(.8);
        transition: transform 200ms ease-out;
    }
}

.initialMessage {
    padding: 8px;
    border-radius: 16px;
    background-color: #F0F3F7;
    max-width: 80%;
    text-align: center;
}

.message {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;

    .message-content {
        padding: 10px;
        border-radius: 8px;
        background-color: #F8F8F8;
        max-width: 75%;
    }



    &.typing {
        .message-content {
            width: max-content;
            color: red;
        }
    }

    &.self {
        align-items: flex-end;

        .message-content {
            background-color: var(--color-blue);
            color: #fff;
        }
    }
}