:root {
	--color-primary: #009fdf;
	--color-secondary: #52c249;
	--color-headings: #595f72;
	--color-accent: #e4464c;
	--color-body: #918ca4;
	--color-brand-text: #5a5a5a;
	--footer-height: 2.875rem;
	--color-blue: #0569ff;
	--table-header: #e0eef7;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
}

legend {
	// width: auto
}

// Custom Button Classes using BEM naming standards

.button {
	padding: 0.6rem 0.8rem;
	font-weight: 600;
	font-size: 0.9rem;
	color: #000;
	border: 0;
	cursor: pointer;
	text-transform: uppercase;
	white-space: nowrap;
	text-align: center;
	background: transparent;

	&[disabled] {
		cursor: not-allowed;
		background: transparent;
		border: 1px solid #85858e;
		color: #85858e;
	}

	&--block {
		width: 100%;
	}

	&--small {
		font-size: 0.8rem;
		padding: 0.5rem 0.7rem;
	}

	&--rounded {
		border-radius: 10px;
	}

	&--primary {
		background: var(--color-primary);
		color: #fff;

		&:hover {
			background: lighten(#009fdf, 5%);
		}
	}

	&--secondary {
		background: var(--color-secondary);
		color: #fff;

		&:hover {
			background: lighten(#52c249, 5%);
		}
	}

	&--outlined {
		background: transparent;
		border: 1px solid #5c5c81;
		color: #5c5c81;
	}

	&--blue {
		color: #fff;
		background: var(--color-blue);

		&:hover {
			background: lighten(#0569ff, 5%);
		}
	}

	&--accent {
		color: #fff;
		background: var(--color-accent);

		&:hover {
			background: lighten(#e4464c, 5%);
		}
	}

	&--outlined-accent {
		background: transparent;
		border: 1px solid var(--color-accent);
		color: var(--color-accent);
	}

	&--yellow {
		color: #fff;
		background: #fe9705;

		&:hover {
			background: lighten(#fe9705, 5%);
		}
	}
}

.btn {
	outline: none;
	padding: 6px 10px;
	border: 1px solid #000;
	margin: 0 10px;
}

.card-border {
	border: 1px solid #dedede;
}

.table thead th {
	background: var(--table-header);
}

.dropdown--outlined {
	background-color: transparent !important;
	border: 1px solid #5c5c81 !important;
	color: #5c5c81 !important;
	border-radius: 10px !important;
	box-shadow: none !important;

	&:hover {
		background-color: transparent !important;
		border: 1px solid #5c5c81 !important;
		color: #5c5c81 !important;
		box-shadow: none !important;
		transform: none !important;
	}

	&:focus {
		background-color: transparent !important;
		border: 1px solid #5c5c81 !important;
		color: #5c5c81 !important;
		box-shadow: none !important;
		transform: none !important;
	}

	&:active {
		background-color: transparent !important;
		border: 1px solid #5c5c81 !important;
		color: #5c5c81 !important;
		box-shadow: none !important;
		transform: none !important;
	}
}

.modal-header {
	border-bottom: 1px solid #b4b4b4;
}

.border-bottom-grey {
	border-bottom: 1px solid #b4b4b4;
}

.form-field {
	background: #ffffff;
	border: 1px solid #b4b4b4;
	border-radius: 5px;
	padding: 12px 17px;
	font-size: 0.875rem;
}

.green-checkbox {
	.form-check-input:checked {
		background-color: #52c249;
		border-color: #52c249;
	}

	.form-check-input:focus {
		border-color: #52c249;
		box-shadow: none;
	}
}

.page-item.active .page-link {
	background: var(--color-primary);
	border-color: var(--color-primary);
	box-shadow: none;
	color: #fff !important;
}

.pagination {
	row-gap: 8px;
}

.table > :not(caption) > * > * {
	color: #3b3e66;
}

.workflow-edit-create-modal-container {
	max-width: 90vw;
}
