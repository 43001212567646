// Core
@import "core";



// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

.visibilty-hidden {
    visibility: hidden;
}

.temp-loading {
    opacity: .5;
    pointer-events: none;
}

.quill-containers-paratexts > .ql-container {
    height: 120px;
}
